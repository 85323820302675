<template>
  <v-select
    v-bind:items="equipmentSizeOptions"
    v-bind:rules="equipmentSizeRules"
    v-model="equipmentSize"
    :label="label"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import equipmentSizes from '../constants/equipmentSizes';
import equipmentTypes from '../constants/equipmentTypes';
import rules from '../constants/rules';

export default {
  props: ['source'],
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('appointment', ['selectedAppointment']),
    label() {
      if (this.source === 'appointment') {
        return 'Size';
      }
      return 'Size *';
    },
    equipmentSize: {
      get() {
        return this.source === 'appointment'
          ? this.selectedAppointment.equipment.size
          : this.gateEntry.equipment.size;
      },
      set(size) {
        if (this.source === 'appointment') {
          this.setAppointmentEquipmentSize(size);
        } else {
          this.setEquipmentSize(size);
        }
      },
    },
    equipmentSizeRules() {
      if (this.source === 'appointment') {
        return [];
      }
      return [rules.rules.required];
    },
    equipmentSizeOptions() {
      let equipmentType = '';
      if (this.gateEntry.equipment.type && this.gateEntry.equipment.type !== '') {
        equipmentType = this.gateEntry.equipment.type;
      } else if (this.selectedAppointment.equipment.type && this.selectedAppointment.equipment.type !== '') {
        equipmentType = this.selectedAppointment.equipment.type;
      }
      switch (equipmentType) {
        case equipmentTypes.CONTAINER:
        case equipmentTypes.REEFER_CONTAINER:
          return [equipmentSizes.TWENTY, equipmentSizes.FORTY, equipmentSizes.FORTY_FIVE];
        case equipmentTypes.CHASSIS:
          return [equipmentSizes.TWENTY, equipmentSizes.FORTY, equipmentSizes.FORTY_FIVE, equipmentSizes.TRIAXLE];
        case equipmentTypes.VAN:
        case equipmentTypes.REEFER_VAN:
          return [equipmentSizes.TWENTY_EIGHT, equipmentSizes.FORTY_EIGHT, equipmentSizes.FIFTY_THREE];
        case equipmentTypes.FLATBED:
          return [equipmentSizes.FORTY_EIGHT, equipmentSizes.FIFTY_THREE];
        case equipmentTypes.TANKER:
          return [equipmentSizes.STANDARD];
        default:
          return [];
      }
    },
  },
  methods: {
    ...mapActions('gate', ['setEquipmentSize']),
    ...mapActions('appointment', { setAppointmentEquipmentSize: 'setEquipmentSize' }),
  },
};
</script>
