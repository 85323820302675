<template>
  <v-select v-bind:items="equipmentTypes"
    v-bind:menu-props="{maxHeight: '100%'}"
    v-model="equipmentType"
    v-on:change="equipmentTypeChanged"
    v-bind:disabled="disabled"
    label="Equipment Type"
    name="equipmentType"
    id="equipmentType"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import equipmentTypes from '../constants/equipmentTypes';
import equipmentStates from '../constants/equipmentStates';

export default {
  props: ['disabled', 'source'],
  data: () => ({
    equipmentTypes: equipmentTypes.ALL_TYPES,
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('appointment', ['selectedAppointment']),
    gateEntryEquipmentType() {
      return this.gateEntry.equipment.type;
    },
    appointmentEquipmentType() {
      return this.selectedAppointment.equipment.type;
    },
    equipmentType: {
      get() {
        return this.source === 'appointment'
          ? this.appointmentEquipmentType
          : this.gateEntryEquipmentType;
      },
      set(equipmentType) {
        if (this.source === 'appointment') {
          this.setAppointmentEquipmentType(equipmentType);
        } else {
          this.setEquipmentType(equipmentType);
        }
      },
    },
  },
  methods: {
    ...mapActions('gate', ['setEquipmentType', 'setEquipmentState', 'setEquipmentSize', 'setEquipmentNumber']),
    ...mapActions('appointment', {
      setAppointmentEquipmentType: 'setEquipmentType',
      setAppointmentEquipmentState: 'setEquipmentState',
      setAppointmentEquipmentSize: 'setEquipmentSize',
      setAppointmentEquipmentNumber: 'setEquipmentNumber',
    }),
    equipmentTypeChanged() {
      if (this.source === 'appointment') {
        this.setAppointmentEquipmentSize('');
        this.setAppointmentEquipmentNumber('');

        if (this.equipmentType === equipmentTypes.CHASSIS) {
          this.setAppointmentEquipmentState(equipmentStates.BARE);
        } else {
          this.setAppointmentEquipmentState('');
        }
      } else {
        this.setEquipmentSize('');
        this.setEquipmentNumber('');

        if (this.equipmentType === equipmentTypes.CHASSIS) {
          this.setEquipmentState(equipmentStates.BARE);
        } else {
          this.setEquipmentState('');
        }
      }
    },
  },
};
</script>
