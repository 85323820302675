<template>
  <v-container class="ma-0 pa-0">
    <v-combobox
        v-model="equipmentNumber"
        item-text="number"
        v-bind:items="comboBoxItems"
        v-bind:rules="equipmentNumberRules"
        v-bind:disabled="disabled"
        v-bind:search-input.sync="searchText"
        :label="label"
        clearable
        v-on:click:clear="clearForm"
    >
      <template v-slot:item="{ index, item }">
        <v-chip label> {{ item.number }} </v-chip>
        <span label class="ml-2"> Size: {{ item.size }} </span>
        <v-spacer></v-spacer>
      </template>
    </v-combobox>
    <v-dialog
      v-bind:persistent="true"
      v-if="requiresValidation"
      v-model="showEquipmentNumberVerification"
      max-width="600">
      <EquipmentNumberVerification
        v-if="showEquipmentNumberVerification"
        v-bind:verificationText="verificationText" />
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Direction from '../constants/direction';
import RuleService from '../services/ruleService';
import EquipmentMapper from '../mappers/equipmentMapper';
import EquipmentNumberVerification from './equipmentNumberVerification.vue';

export default {
  props: {
    disabled: Boolean,
    requiresValidation: Boolean,
    allowAutocomplete: Boolean,
    source: String,
  },
  data: () => ({
    verificationText: '',
  }),
  components: {
    EquipmentNumberVerification,
  },
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('appointment', ['selectedAppointment']),
    ...mapGetters('equipment', ['equipmentWithLocationByType', 'showEquipmentNumberVerification', 'equipmentNumberSearch']),
    ...mapGetters('check', ['lostEquipmentByType']),
    ...mapGetters('expectedGateActivity', [
      'selectedGateActivity',
      'filteredExpectedGateActivity',
      'expectedEquipmentNumberFilter']),
    equipmentNumber: {
      get() {
        return this.source === 'appointment'
          ? this.selectedAppointment.equipment.number
          : this.gateEntry.equipment.number;
      },
      set(value) {
        if (this.source === 'appointment') {
          this.setAppointmentEquipmentNumber(value);
        } else {
          let equipmentNumber = '';
          this.setSelectedGateActivity({});
          if (typeof value === 'string') {
            // An unknown equipment number was entered into the combobox.
            equipmentNumber = value;
            this.verificationText = value;
            if (this.allowAutocomplete && this.filteredExpectedGateActivity.length === 1 && this.filteredExpectedGateActivity[0].equipment.number.toUpperCase() === equipmentNumber.toUpperCase()) {
              this.setEquipment(this.filteredExpectedGateActivity[0].equipment);
              this.verificationText = equipmentNumber.slice(-3);
            }
          } else if (typeof value === 'object' && value != null) {
            // A record was selected from the combobox.
            equipmentNumber = value.number;
            this.setEquipment(value);
            this.verificationText = equipmentNumber.slice(-3);
          }

          this.setEquipmentNumber(equipmentNumber);

          if (this.hasEnteredValidEquipmentNumber()) {
            if (this.gateEntry.direction === Direction.IN && this.knownEquipment.some((equipment) => equipment.number.toUpperCase() === equipmentNumber.toUpperCase())) {
              this.clearForm();
              this.raiseError('Equipment already exists on yard');
            } else if (typeof value === 'object' || value.length > 1) {
              this.setShowEquipmentNumberVerification(true);
            }
          }
        }
      },
    },
    label() {
      if (this.source === 'appointment') {
        return 'Equipment Number';
      }
      return 'Equipment Number *';
    },
    equipmentNumberRules() {
      if (this.source === 'appointment') {
        return [];
      }
      return RuleService.getEquipmentNumberRules(this.gateEntry.equipment.type);
    },
    knownEquipment() {
      if (this.equipmentWithLocationByType) {
        const equipmentOnYard = this.equipmentWithLocationByType;
        if (this.lostEquipmentByType) {
          const lostEquipment = this.lostEquipmentByType.map((a) => a.equipment);
          const equipmentInYardOrLost = [...equipmentOnYard, ...lostEquipment];
          return equipmentInYardOrLost;
        }
        return equipmentOnYard;
      }
      return [];
    },
    searchText: {
      get() {
        return this.expectedEquipmentNumberFilter;
      },
      set(value) {
        if (value) {
          value = value.toUpperCase();
          this.setExpectedEquipmentNumberFilter(value);
          this.setEquipmentNumberSearch(value);
        }

        if (value === '') {
          this.clearForm();
        }
      },
    },
    isGateIn() {
      return this.gateEntry.direction === Direction.IN;
    },
    comboBoxItems() {
      if (!this.allowAutocomplete) {
        return [];
      }

      if (this.isGateIn) {
        return this.filteredExpectedGateActivity.map((ega) => ega.equipment);
      }

      return this.filteredKnownEquipment;
    },
    filteredKnownEquipment() {
      if (this.searchText === null || this.searchText === '') {
        return [];
      }

      return this.knownEquipment.filter((equipment) => equipment.number.toUpperCase().includes(this.equipmentNumberSearch.toUpperCase()));
    },
  },
  methods: {
    ...mapActions('gate', ['setEquipmentNumber', 'setEquipmentType', 'setEquipment']),
    ...mapActions('appointment', {
      setAppointmentEquipmentType: 'setEquipmentType',
      setAppointmentEquipmentNumber: 'setEquipmentNumber',
    }),
    ...mapActions('expectedGateActivity', ['resetState', 'setSelectedGateActivity', 'setExpectedEquipmentNumberFilter']),
    ...mapActions('alert', ['raiseError']),
    ...mapActions('equipment', ['setShowEquipmentNumberVerification', 'setEquipmentNumberSearch']),
    clearForm() {
      if (this.source === 'appointment') {
        const previousType = this.selectedAppointment.equipment.type;
        this.setAppointmentEquipmentType(previousType);
      } else {
        const previousType = this.gateEntry.equipment.type;
        this.setEquipment(_.cloneDeep(EquipmentMapper.defaultEquipment));
        this.setEquipmentType(previousType);
      }
    },
    hasEnteredValidEquipmentNumber() {
      let hasValidEquipmentNumber = true;
      const rules = RuleService.getEquipmentNumberRules(this.gateEntry.equipment.type);
      rules.forEach((rule) => {
        if (rule(this.gateEntry.equipment.number) !== true) {
          hasValidEquipmentNumber = false;
        }
      });
      return hasValidEquipmentNumber;
    },
  },
  created() {
    this.resetState();
  },
  watch: {
    searchText(value) {
      if (value === '') {
        this.clearForm();
      }
    },
  },
};
</script>
