<template>
    <v-select
        v-bind:items="equipmentStateOptions"
        v-bind:rules="equipmentStateRules"
        v-bind:disabled="shouldBeDisabled"
        v-model="equipmentState"
        :label="label"
    ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';
import equipmentService from '../services/equipmentService';

export default {
  props: ['source'],
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('appointment', ['selectedAppointment']),
    label() {
      if (this.source === 'appointment') {
        return 'Loaded Status';
      }
      return 'Loaded Status *';
    },
    equipmentState: {
      get() {
        return this.source === 'appointment'
          ? this.selectedAppointment.equipment.state
          : this.gateEntry.equipment.state;
      },
      set(state) {
        if (this.source === 'appointment') {
          this.setAppointmentEquipmentState(state);
        } else {
          this.setEquipmentState(state);
        }
      },
    },
    equipmentStateRules() {
      if (this.source === 'appointment') {
        return [];
      }
      return [rules.rules.required];
    },
    equipmentStateOptions() {
      let equipmentType = '';
      if (this.gateEntry.equipment.type && this.gateEntry.equipment.type !== '') {
        equipmentType = this.gateEntry.equipment.type;
      } else if (this.selectedAppointment.equipment.type && this.selectedAppointment.equipment.type !== '') {
        equipmentType = this.selectedAppointment.equipment.type;
      }
      return equipmentService.getEquipmentStateOptions(equipmentType);
    },
    shouldBeDisabled() {
      return this.equipmentStateOptions.length === 1;
    },
  },
  methods: {
    ...mapActions('gate', ['setEquipmentState']),
    ...mapActions('appointment', { setAppointmentEquipmentState: 'setEquipmentState' }),
  },
};
</script>
