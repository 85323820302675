<template>
  <v-container>
    <v-card>
      <v-card-title v-if="isPartialVerification">
        Verify the last 3 Characters of the Equipment Number:
      </v-card-title>
      <v-card-title v-else>
        The equipment number you entered is not in the system. <br /> <br /> To continue, please re-enter the equipment number below:
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="verificationInput"
              v-bind:maxLength="verificationText.length"
              solo
              dense
              :hint="hint"
              autofocus
            >
              <h2 v-if="verificationText.length === 3" slot="prepend" class="pt-0 pr-2">{{maskedEquipmentNumber}}</h2>
            </v-text-field>
          </v-col>
          <v-col cols="3" >
            <v-btn @click="closeClicked" color="secondary">
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['verificationText'],
  data: () => ({
    input: '',
    attemptCount: 0,
    verificationResponseMessage: true,
  }),
  methods: {
    ...mapActions('gate', ['setEquipmentNumber', 'setEquipmentState', 'setEquipmentSize', 'setCarrierSCAC', 'setCustomer']),
    ...mapActions('equipment', ['setShowEquipmentNumberVerification']),
    ...mapActions('alert', ['raiseInformation']),
    isInputCorrect() {
      return this.input.toUpperCase() === this.verificationText.toUpperCase();
    },
    closeClicked() {
      this.setEquipmentNumber('');
      this.setShowEquipmentNumberVerification(false);
    },
  },
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('expectedGateActivity', ['selectedGateActivity']),
    hint() {
      let hintString = '';
      if (this.attemptCount > 0 && this.verificationMatchLength === 3) {
        hintString = `Input is incorrect. Please confirm the equipment number to the left is correct. Attempt: ${this.attemptCount}/2`;
      } else if (this.attemptCount > 0 && this.verificationMatchLength !== 3) {
        hintString = `Equipment Number does not match. Please re-enter the Equipment Number to gate out. Attempt: ${this.attemptCount}/2`;
      }
      return hintString;
    },
    maskedEquipmentNumber() {
      const maskedEquipmentNumber = `${this.gateEntry.equipment.number.substring(0, this.gateEntry.equipment.number.length - 3)}XXX`;
      return maskedEquipmentNumber;
    },
    isPartialVerification() {
      return this.verificationText.length === 3;
    },
    verificationInput: {
      get() {
        return this.input;
      },
      set(value) {
        this.input = value.toUpperCase();
      },
    },
  },
  watch: {
    input(value) {
      if (value.length === this.verificationText.length) {
        if (this.isInputCorrect()) {
          if (this.selectedGateActivity.equipment) {
            this.setEquipmentNumber(this.selectedGateActivity.equipment.number);
            this.setEquipmentState(this.selectedGateActivity.equipment.state);
            this.setEquipmentSize(this.selectedGateActivity.equipment.size);
            this.setCarrierSCAC(this.selectedGateActivity.equipment.carrier.scac);
            this.setCustomer(this.selectedGateActivity.equipment.customer.name);
          }
          this.setShowEquipmentNumberVerification(false);
        } else {
          this.attemptCount += 1;
          this.input = '';
          if (this.attemptCount >= 2) {
            if (this.isPartialVerification) {
              this.raiseInformation('Your input did not match the equipment number chosen. Please make sure to choose the correct equipment number.');
            } else {
              this.raiseInformation('Your input did not match the equipment number entered. Please make sure to enter the correct equipment number.');
            }
            this.setEquipmentNumber('');
            this.setShowEquipmentNumberVerification(false);
          }
        }
      }
    },
  },
};
</script>
