const TWENTY = '20';
const TWENTY_EIGHT = '28';
const FORTY = '40';
const FORTY_FIVE = '45';
const TRIAXLE = 'Triaxle';
const FORTY_EIGHT = '48';
const FIFTY_THREE = '53';
const BOX = 'Box';
const STANDARD = 'Standard';

export default {
  TWENTY,
  TWENTY_EIGHT,
  FORTY,
  FORTY_FIVE,
  FORTY_EIGHT,
  FIFTY_THREE,
  TRIAXLE,
  BOX,
  STANDARD,
};
