import equipmentTypes from '../constants/equipmentTypes';
import rules from '../constants/rules';

function getEquipmentNumberRules(equipmentType) {
  const equipmentNumberRules = [rules.rules.required, rules.rules.alphaNumeric];

  if (equipmentType === equipmentTypes.CONTAINER || equipmentType === equipmentTypes.REEFER_CONTAINER) {
    equipmentNumberRules.splice(1, 0, rules.rules.validContainerNumber);
  }

  return equipmentNumberRules;
}

export default {
  getEquipmentNumberRules,
};
